import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { ConfigService } from './_services/config.service'
import { CommonModule } from '@angular/common'
import { AuthService } from './_services/auth.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterOutlet],
})
export class AppComponent implements OnInit {
    init = false
    constructor(
        private configService: ConfigService,
        private authService: AuthService
    ) {}

    async ngOnInit() {
        // Load config on app initialization
        this.authService.authSource.subscribe(async (auth) => {
            if (auth?.user) await this.configService.loadConfig()
        })
    }
}
